import "./Fashion.css";
import sweaterPicture from "./assets/fashion/sweater/sweater.jpg";

function Fashion() {
  return (
    <div className="content">
      <div className="description">
        <p>
          My drive for simplicity and harmony extends beyond my keyboard.
          Sewing, and by extension fashion and design, is a welcome creative
          outlet of mine. I enjoy learning, methodical approaches and the
          satisfaction of making something by hand. Below are a collection of my
          projects, both completed and in-progress.
        </p>
      </div>
      <div className="list">
        <div className="item">
          <img src={sweaterPicture} alt="swetaer" height="150" />
          <div className="fashion-text">
            <h1>Sweater</h1>
            <p>
              I made this sweater for my partner. I used a pattern from 'Sew
              Your Own Scandi Wardrobe' by Oda Stormoen and Kristin Vaag as a
              base, altering the crop to give a boxy look. It is made in a dark
              blue fleece with ribbed cuffs, hem and collar.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fashion;
