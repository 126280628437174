import mvp from "../assets/projects/TimeTracker/MVP.JPG";
import "./FootballSchedule.css";
import "./Project.css";

function TimeTracker() {
  return (
    <div className="projectContent">
      <div className="title">
        <h1>Time Tracker</h1>
        <div className="skills">
          <p>
            <b>Skills used:</b> Python, SQLite, tkinter
          </p>
        </div>
      </div>

      <h2>Motivation:</h2>
      <p>
        I wanted to track how long I spend on certain projects or tasks, so I
        decided to make a simple Python GUI application to do just that. My
        mother also does some feelance work, for which she tracks her hours with
        pen and paper, so I built it with her as the intended target audience.
      </p>
      <h2>Minimum viable product:</h2>
      <p>
        The most basic required functions are:
        <li>
          Take a text input from the user for the name of the current task.
        </li>
        <li>A stopwatch with start, stop, reset and save buttons.</li>
        <li>A database to store the name and time spent on that task.</li>
        <li>Display the data.</li>
        It uses SQLite to store the data locally
      </p>
      <img src={mvp} alt="mvp" height="300" />
      <br />

      <div className="projectToDo">
        <b>Planned updates/changes:</b>
        <li>
          Include a dropdown menu with past project names, to make selection
          faster, and to avoid duplication caused by typos.
        </li>
        <li>the ability to update or delete a project from the databse.</li>
      </div>
      <a href="https://github.com/kaiRO99/time_tracker" target="_blank" rel="noopener noreferrer">
        <img
          src="https://img.shields.io/badge/GitHub-100000?style=for-the-badge&logo=github&logoColor=white"
          alt="github"
        />
      </a>
    </div>
  );
}

export default TimeTracker;
