import { useNavigate } from "react-router-dom";
import "./Projects.css";

function Projects() {
  const navigate = useNavigate(); //do I need this? of call useNAvigate in each element

  return (
    <div className="content">
      <div className="description">
        <p>
          I’m detail focused and driven to find the balance between simplicity,
          efficiency and harmony in projects. I’m intentional about my actions
          and have spent the last year widening my network and sharpening my
          code.
        </p>
      </div>
      {/* standardize item classNames so they all have same css */}
      <div className="projectList">
        <div
          className="project-item"
          onClick={() => navigate("/Projects/Portfolio")}
        >
          <div className="project-text">
            <h1>This Portfolio</h1>
            <p>
              This website is both a showcase of my interests, and a
              self-motivated initiative to learn and practice frontend
              development.
            </p>
            <p>
              <b>Skills used:</b> React.js, JavaScript, HTML, CSS, Git
            </p>
          </div>
        </div>

        <div
          className="project-item"
          onClick={() => navigate("/Projects/FootballScheduler")}
        >
          <div className="project-text">
            <h1>Football Scheduler*</h1>
            <p>
              A web app to add the match schedule of multiple teams from across
              leagues to one calendar.
            </p>
            <p>
              <b>Skills used:</b> React.js, Node.js, Express.js, JavaScript,
              MongoDB, HTML, CSS, Git, RESTful APIs
            </p>
          </div>
        </div>

        <div
          className="project-item"
          onClick={() => navigate("/Projects/TimeTracker")}
        >
          <div className="project-text">
            <h1>Time Tracker</h1>
            <p>
              A Python GUI time tracking application
            </p>
            <p>
              <b>Skills used:</b> Python, SQLite, tkinter
            </p>
          </div>
        </div>

        <div
          className="project-item"
          onClick={() => navigate("/Projects/ChampionshipStatus")}
        >
          <div className="project-text">
            <h1>Championship Status</h1>
            <p>
            Tracks if a team/driver is out of the championship. Tracks Formula 1, LaLiga and the Premier League
            </p>
            <p>
              <b>Skills used:</b> Django, Python, MongoDB, APIs
            </p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Projects;
