// import "@fortawesome/fontawesome-free/css/all.min.css";
import { IconContext } from "react-icons";
import { RxCaretDown } from "react-icons/rx";
import { SlArrowDown } from "react-icons/sl";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="home">
        <NavLink exact to="/" className={"name"}>
          Kai Ryall Ota
        </NavLink>
      </div>
      <div className="pages">
        <div className="dropdown">
          <NavLink to="/Projects" className={"navItem dropdown-parent"}>
            Projects
            <RxCaretDown className="downIcon" />
          </NavLink>
          <div className="dropdown-items">
            <NavLink to="/Projects/Portfolio" className={"dropItem"}>
              Portfolio
            </NavLink>
            <NavLink to="/Projects/FootballScheduler" className={"dropItem"}>
              Football Scheduler
            </NavLink>
            <NavLink to="/Projects/TimeTracker" className={"dropItem"}>
              Time Tracker
            </NavLink>
            <NavLink to="/Projects/ChampionshipStatus" className={"dropItem"}>
              Championship Status
            </NavLink>
          </div>
        </div>
        <NavLink to="/Gallery" className={"navItem"}>
          Photography
        </NavLink>
        {/* <NavLink to="/Fashion" className={"navItem"}>
          Fashion
        </NavLink> */}
        {/* <NavLink to="/Resume" className={"navItem"}>
          Resume
        </NavLink> */}
      </div>
    </nav>
  );
}

export default Navbar;
