import "./Bio.css";
import picture from "./assets/me.jpeg";

const Bio = () => {
  return (
    <div className="bio">
      <div class="homepagetxt">
        <p>
          Hi, I’m <i>Kai</i>, a creative and hardworking individual, looking to
          start a career in software development. I have a unique background in
          mental health psychology, with a concentration in computer science.
          This portfolio is an ongoing record of my projects and interests.
        </p>
      </div>
      <div class="homepageimg">
        <img src={picture} alt="me" height="600" />
      </div>
    </div>
  );
};

export default Bio;
