import { IconContext } from "react-icons";
// import { FiInstagram } from "react-icons/fi";
import { LuGithub, LuInstagram, LuLinkedin, LuMail } from "react-icons/lu";

import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <IconContext.Provider value={{ color: "white" }}>
        <div className="sm-icons">
          <a
            href="https://www.instagram.com/kai_ota/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LuInstagram className="icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/kai-ryall-ota"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LuLinkedin className="icon" />
          </a>
          <a
            href="https://github.com/kaiRO99"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LuGithub className="icon" />
          </a>
          <a
            href="mailto:kai.ryallota@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LuMail className="icon" />
          </a>
        </div>
      </IconContext.Provider>
    </footer>
  );
}

export default Footer;
