import "./Project.css";

function Portfolio() {
  return (
    <div className="projectContent">
      <h1>This Portfolio</h1>
      <p>
        I made this portfolio to showcase my work and interests. Though it could
        have been a static website, I decided to do it in React because there
        are useful packages for what I wanted, and it was also an opportunity to
        practise. <br />
        It is built with three main components, the navigation bar, the body and
        footer. The Gallery was made with the react-masonry-css package as I am
        going to include images with different aspect ratios and orientations.
        This allowed me to make a gallery that was responsive to different files
        and screens whilst maintaining a cohesive appearance. Git was used for
        version control and to allow me to work from either my laptop or
        desktop. It was deployed using AWS Amplify. It is still a work in
        progress and I will continuously update as I have more photos, projects
        or design changes.
      </p>
      <p>
        <b>Skills used:</b> React, JavaScript, HTML, CSS, Git, AWS Amplify
      </p>
      <div className="projectToDo">
        <>
          <b>Planned updates/changes:</b>

          <li>Make it adaptive to screen sizes.</li>
          <li>Make it mobile friendly.</li>
          <li>Add a page showing my Resume.</li>
          <li>Make images clickable and add descriptions.</li>
          <li>Change image sizes to make gallery load faster.</li>
        </>
      </div>
    </div>
  );
}

export default Portfolio;
